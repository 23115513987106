:root {
  --gray: #dcdcdc;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body,
input,
button,
dialog,
select {
  font: 20px/1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Roboto;
}

.note {
  font: 15px/1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Roboto;

  font-style: italic;
}

h1,
h2,
h3,
button {
  text-align: center;

  margin-top: 5px;
  margin-bottom: 5px;
}

.announcement {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 15px;
}

.announcement img {
  min-width: 300px;
  max-height: 400px;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 800px;
  height: 100%;

  margin-left: auto;
  margin-right: auto;
}

img {
  max-width: 50px;
  max-height: 50px;

  user-select: none;

  border-style: none;
  display: inline;
}

button {
  min-height: 30px;
  min-width: 140px;

  padding: 6px;
  border-radius: 10px;

  align-self: center;
}

.cart-list {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 10px;
}

.cart-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  flex-grow: 1;
  min-height: 60px;

  background-color: var(--gray);

  padding-left: 6px;
  padding-right: 6px;

  padding-top: 3px;
  padding-bottom: 3px;

  border-radius: 10px;

  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease;
}

.cart-item.fading-out {
  opacity: 0;
}

.cart-item .left {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-grow: 1;

  gap: 15px;
}

.item-quantity.wrapper {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;

  min-width: 60px;
}

.item-quantity.inner {
  display: flex;
  flex-direction: row;

  gap: 3px;
}

.cart-item .right {
  display: flex;
  flex-direction: row;
  justify-content: right;

  gap: 10px;

  margin-left: auto;
  margin-right: auto;
}

.quantity-controls {
  line-height: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 5px;
}

.item-complete {
  line-height: 0;
}

.left,
.right {
  margin-top: 2.5px;
  margin-bottom: 2.5px;

  align-items: center;
}

.hidden {
  display: none;
}
