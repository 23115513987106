:root {
  --gray: #dcdcdc;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body, input, button, dialog, select {
  font: 20px / 1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Roboto;
}

.note {
  font: italic 15px / 1.5 -apple-system, BlinkMacSystemFont, Segoe UI, Roboto;
}

h1, h2, h3, button {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.announcement {
  flex-direction: column;
  align-items: center;
  gap: 15px;
  display: flex;
}

.announcement img {
  min-width: 300px;
  max-height: 400px;
}

main {
  max-width: 800px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

img {
  max-width: 50px;
  max-height: 50px;
  -webkit-user-select: none;
  user-select: none;
  border-style: none;
  display: inline;
}

button {
  min-height: 30px;
  min-width: 140px;
  border-radius: 10px;
  align-self: center;
  padding: 6px;
}

.cart-list {
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  display: flex;
}

.cart-item {
  min-height: 60px;
  background-color: var(--gray);
  border-radius: 10px;
  flex-flow: wrap;
  flex-grow: 1;
  justify-content: space-between;
  padding: 3px 6px;
  transition-property: opacity;
  transition-duration: .5s;
  transition-timing-function: ease;
  display: flex;
}

.cart-item.fading-out {
  opacity: 0;
}

.cart-item .left {
  flex-direction: row;
  flex-grow: 1;
  justify-content: left;
  gap: 15px;
  display: flex;
}

.item-quantity.wrapper {
  min-width: 60px;
  flex-direction: row;
  flex-shrink: 1;
  display: flex;
}

.item-quantity.inner {
  flex-direction: row;
  gap: 3px;
  display: flex;
}

.cart-item .right {
  flex-direction: row;
  justify-content: right;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.quantity-controls {
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  line-height: 0;
  display: flex;
}

.item-complete {
  line-height: 0;
}

.left, .right {
  align-items: center;
  margin-top: 2.5px;
  margin-bottom: 2.5px;
}

.hidden {
  display: none;
}

/*# sourceMappingURL=index.10e014cb.css.map */
